@import 'src/styles/mixins.scss';

.section {
  min-height: 30rem;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  flex-wrap: nowrap;
  position: relative;
  overflow: visible;
  padding-top: 3rem;
  padding-bottom: 6rem;
  flex-shrink: 0;
  flex-grow: 1;
}

.img {

  padding-top: 3rem;
  padding-bottom: 16rem; // includes height of border svg
  @media screen and (max-width: 35rem) {
    padding-bottom: 14rem;
  }
}

.small {
  min-height: 5rem;
  padding-top: 0.2rem;
  padding-bottom: 3rem;

  &.img {
    padding-bottom: 11rem; // includes height of border svg
    @media screen and (max-width: 35rem) {
      padding-bottom: 9rem;
    }
  }
}

.border {
  height: 10rem;
  @media screen and (max-width: 35rem) {
    height: 8rem;
  }
  width: 100vw;
  position: absolute;
  bottom: 0;
  z-index: 10;
}

.brown {
  background-color: $brown;
  color: $light;
}
.orange {

  background: $orange;
  // background: linear-gradient(to bottom, $orange, $pale 250%);
  color: $brown;
}
.blue {

  background: $blue;
  // background: linear-gradient(to bottom, $blue, $pale 250%);
  color: $brown;
}
.green {
  background: $green;
  // background: linear-gradient(to bottom, $green, $pale 250%);
  color: $brown;
}
.lightBlue {

  background: $lightBlue;
  // background: linear-gradient(to bottom, $lightBlue, $pale 250%);
  color: $brown;
}
.red {
  background: $red;
  // background: linear-gradient(to bottom, $red, $pale 250%);
  color: $brown;
}
.light {
  background: $light;
  // background: linear-gradient(to bottom, $light, $pale 250%);
  color: $brown;
}
.lightGreen {
  background: $lightGreen;
  // background: linear-gradient(to bottom, $light, $pale 250%);
  color: $brown;
}